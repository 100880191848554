import dayjs from "dayjs";
export const columns = [
  {
    title: "名字",
    dataIndex: "username",
    key: "userName",
  },
  {
    title: "性别",
    dataIndex: "gender",
    key: "userGender",
  },
  {
    title: "班级",
    dataIndex: "class.name",
    key: "className",
    render: (_, { class: className }) => <span>{className.name}</span>,
  },
  {
    title: "邮箱",
    dataIndex: "email",
    key: "userEmail",
  },
  {
    title: "是否提交",
    dataIndex: "surveyAnswers",
    key: "surveyBatch",
    render: (_, { surveyAnswers }) => <span>{surveyAnswers.length > 0 ? "是" : "否"}</span>,
  },
  {
    title: "提交批次",
    dataIndex: "surveyAnswers",
    key: "surveyBatch",
    render: (_, { surveyAnswers }) => <span>{surveyAnswers[0] ? surveyAnswers[0].survey.batch : ""}</span>,
  },
  {
    title: "提交时间",
    dataIndex: "surveyAnswers",
    key: "answerCreatedAt",
    render: (_, { surveyAnswers }) => <span>{surveyAnswers[0] ? dayjs(surveyAnswers[0].createAt).format("YYYY/MM/DD HH:mm:ss") : ""}</span>,
  },
];
